import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import pm from "@/core/helpers/ProcessManager";

import { Htag, ReserveCurrencyInfo, Timer } from '@/components';

import { ClientInfo } from "@/redux/modules/ClientInfo";
import { RateExchange } from "@/redux/modules/RateExchange";

import styles from "./reservation.module.css";
import { Button } from '@mui/material';

const Reservation = () => {
  const rateExchange: RateExchange = useSelector(
    (state: any) => state?.rateExchange || {}
  );
  const clientInfo: ClientInfo = useSelector(
    (state: any) => state?.clientInfo || {}
  );

  return (
    <div className={styles.background_color}>
      <div className={styles.container}>
        <Htag tag={'h1'}>Мы забронировали для Вас выгодный курс</Htag>
        <ReserveCurrencyInfo rateExchange={rateExchange} />
        <Timer />
        <div className={styles.btn_group}>
          <Link
            to="/"
            style={{
              width: "100%",
              color: "white",
              textDecoration: "none",
            }}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "#fd5f00",
                "&:hover": {
                  backgroundColor: "#a13d01",
                },
              }}>
              Назад
            </Button>
          </Link>
          <Button
            sx={{
              backgroundColor: "#005792",
              '&:hover': {
                backgroundColor: "#13334c",
              },
            }}
            fullWidth
            variant="contained"
            size="large"
            onClick={() => {
              pm.startPublic({
                processDefinitionKey: "client_request",
                variables: {
                  depsId: rateExchange.depsId,
                  buyCurrencyCode: rateExchange.buyCurrencyCode,
                  saleCurrencyCode: rateExchange.saleCurrencyCode,
                  buyAmount: +rateExchange.buyAmount,
                  saleAmount: +rateExchange.saleAmount,
                  currentRate: +rateExchange.rate,
                  isIndividual: rateExchange.isIndividual,
                  phone: clientInfo.phone
                    .replace(/\s/g, "")
                    .replace(/-/g, "")
                    .replace(/\+/g, ""),
                  iin: clientInfo.iin
                },
              });
            }}>
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Reservation;
