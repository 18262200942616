import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { HeaderReducer, actions } from "@/redux/modules/Header";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const basePath = process.env.PUBLIC_URL;

const Header = (props: HeaderReducer & { isLoading: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pathTo = props.userContext?.user?.roles?.find(
    (item) => item.code === "ADMIN"
  )
    ? { path: "/cash-balance", title: "Баланс касс", index: 0 }
    : props.userContext?.user?.roles?.find((item) => item.code === "Agent")
      ? { path: "/agent-clients", title: "Клиенты", index: 3 }
      : props.userContext?.user?.roles?.find((item) => item.code === "Treasurer")
        ? { path: "/treasurer-rates", title: "Курсы", index: 1 }
        : { path: "/login", title: "Курсы", index: 1 };

  const handleClickProfile = ({ path, title, index, }: { path: string; title: string; index: number; }) => {
    dispatch(actions.selectMenu({ path, title, index }));
    dispatch(actions.openQueueMenuBar(false));
    navigate(path);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: "unset",
        borderBottom: "1px solid #b8b8b8",
      }}>
      <Toolbar
        sx={{
          height: 50,
          overflow: "hidden",
          background: "#f6f6e9",
          pr: "24px", // keep right padding when drawer closed
          display: "flex",
          justifyContent: "space-between",
        }}>
        <IconButton href="/">
          <img
            src={`${basePath}/LOGOSVG1.svg`}
            style={{
              // display: "inline-block",
              // position: "absolute",
              width: 156,
              left: 30,
            }}
          />
        </IconButton>
        <p
          style={{
            color: "black",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => handleClickProfile(pathTo)}>
          Войти
        </p>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps(state: any) {
  const header: HeaderReducer = state.header;

  return {
    userContext: header.userContext,
    menuBarOpen: header.menuBarOpen,
    selectedMenu: header.selectedMenu,
    selectedQueueMenu: header.selectedQueueMenu,
  };
}

export default connect(mapStateToProps, null)(Header);
