import * as React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Error404,
  Login,
  Reports,
  StartProcess,
  MyTasks,
  Users,
  Roles,
  UserProfile,
  TaskList,
  Queues,
  Calendars,
  History,
  ClientExchange,
  Treasure,
  DocumentPage,
  CashBalance,
  RequestList,
  Receipt,
  DepartmentOpen
} from "./pages";
import authorizedLayout from "@/components/AuthorizedLayout";
import invisibleLayout from "./components/InvisibleLayout";
import { history } from "./core/helpers/history";
import CustomBrowserRouter from "./CustomBrowserRouter";
import Slas from "./pages/Slas";
import Processes from "./pages/Processes";
import MainPageLayout from './components/MainPageLayout/MainPageLayout';

const RoutesComponent = () => (
  <React.StrictMode>
    <CustomBrowserRouter history={history}>
      <Routes>
        <Route
          path="login"
          element={invisibleLayout(Login)}
          errorElement={<Error404 />}
        />

        <Route
          path="startProcess"
          element={authorizedLayout(StartProcess)}
          errorElement={<Error404 />}
        />
        <Route
          path="task/:id/*"
          element={<Processes />}
          errorElement={<Error404 />}
        />
        <Route
          path="mytasks"
          element={authorizedLayout(MyTasks)}
          errorElement={<Error404 />}
        />
        <Route
          path="queue"
          element={authorizedLayout(TaskList)}
          errorElement={<Error404 />}
        />
        <Route
          path="history/*"
          element={<History />}
          errorElement={<Error404 />}
        />
        <Route
          path="reports"
          element={authorizedLayout(Reports)}
          errorElement={<Error404 />}
        />
        <Route
          path="profile"
          element={authorizedLayout(UserProfile)}
          errorElement={<Error404 />}
        />
        <Route path="users/*" element={<Users />} errorElement={<Error404 />} />
        <Route path="roles/*" element={<Roles />} errorElement={<Error404 />} />
        <Route
          path="queues/*"
          element={<Queues />}
          errorElement={<Error404 />}
        />
        <Route
          path="calendars/*"
          element={<Calendars />}
          errorElement={<Error404 />}
        />
        <Route path="slas/*" element={<Slas />} errorElement={<Error404 />} />
        <Route
          path="/*"
          element={MainPageLayout(ClientExchange)}
          errorElement={<Error404 />}
        />
        <Route
          path="department-open/*"
          element={authorizedLayout(DepartmentOpen)}
          errorElement={<Error404 />}
        />
        <Route
          path="treasurer-rates/*"
          element={authorizedLayout(Treasure)}
          errorElement={<Error404 />}
        />
        <Route
          path="request-list/*"
          element={authorizedLayout(RequestList)}
          errorElement={<Error404 />}
        />
        <Route
          path="/documents/*"
          element={authorizedLayout(DocumentPage)}
          errorElement={<Error404 />}
        />
        <Route
          path="/cash-balance/*"
          element={authorizedLayout(CashBalance)}
          errorElement={<Error404 />}
        />
        <Route
          path="/receipt/*"
          element={MainPageLayout(Receipt)}
          errorElement={<Error404 />}
        />
      </Routes>
    </CustomBrowserRouter>
  </React.StrictMode>
);

export default RoutesComponent;