import React, { useState, ChangeEvent } from "react";
import { Link } from 'react-router-dom';

import pm from "@/core/helpers/ProcessManager";

import { ModalReserveProps } from './ModalReserve.props';
import { BtnDarkBlue } from '@/components/MuiComponent';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from '@mui/material/TextField';
import InfoIcon from "@mui/icons-material/Info";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";

import InputMask from "react-input-mask";

import styles from "./ModalReserve.module.css";

const ModalReserve = ({ openWindow, setOpenWindow, handleChangePhone, clientInfo }: ModalReserveProps): JSX.Element => {
	const [step, setStep] = useState(0);

	const handleCloseWindow = () => {
		setOpenWindow({ open: false, text: "", button: false });
		setStep(0);
		handleChangePhone("");
	};

	const checkPhone = (phone: string) => {
		if (
			phone.slice(3, 6) === "700" ||
			phone.slice(3, 6) === "701" ||
			phone.slice(3, 6) === "702" ||
			phone.slice(3, 6) === "703" ||
			phone.slice(3, 6) === "704" ||
			phone.slice(3, 6) === "705" ||
			phone.slice(3, 6) === "706" ||
			phone.slice(3, 6) === "707" ||
			phone.slice(3, 6) === "708" ||
			phone.slice(3, 6) === "709" ||
			phone.slice(3, 6) === "747" ||
			phone.slice(3, 6) === "750" ||
			phone.slice(3, 6) === "751" ||
			phone.slice(3, 6) === "760" ||
			phone.slice(3, 6) === "761" ||
			phone.slice(3, 6) === "762" ||
			phone.slice(3, 6) === "763" ||
			phone.slice(3, 6) === "764" ||
			phone.slice(3, 6) === "771" ||
			phone.slice(3, 6) === "775" ||
			phone.slice(3, 6) === "776" ||
			phone.slice(3, 6) === "777" ||
			phone.slice(3, 6) === "778" ||
			phone.slice(3, 6) === "700" ||
			phone.slice(3, 6) === "700" ||
			phone.slice(3, 6) === "700" ||
			phone.slice(3, 6) === "700" ||
			phone.slice(3, 6) === "700"
		) {
			return true;
		}
		return false;
	};

	const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
		handleChangePhone(e.target.value);
		if (clientInfo.phone.replace(/_/g, "").length === 16 && !checkPhone(clientInfo.phone)) {
			pm.openSnackBar("Введен несуществующий номер телефона", { severity: "error" });
		}
	};

	return (
		<Dialog
			open={openWindow.open}
			onClose={handleCloseWindow}
			maxWidth="xs"
		>
			{step === 0 && (
				<>
					<DialogTitle className={styles.justifyCenter}>
						<InfoIcon className={styles.info_icon} />
					</DialogTitle>
					<DialogContent>
						<DialogContentText className={styles.text}>
							{openWindow.text}
						</DialogContentText>
					</DialogContent>
					<DialogActions className={styles.justifyCenter}>
						{openWindow.button && (
							<BtnDarkBlue
								fullWidth
								variant="contained"
								size="large"
								startIcon={<PriceCheckIcon />}
								onClick={() => setStep(1)}>
								Зафиксировать курс
							</BtnDarkBlue>
						)}
					</DialogActions>
				</>
			)}
			{step === 1 && (
				<form onSubmit={(e) => e.preventDefault()}>
					<DialogContent>
						<DialogContentText
							className={styles.text}
							sx={{
								marginBottom: "20px",
							}}
						>
							Введите номер телефона
						</DialogContentText>
						<InputMask
							mask="+7 799 999-99-99"
							value={clientInfo.phone}
							onChange={onChangePhone}
						>
							<TextField
								className={styles.textField}
								autoComplete={"off"}
								label="Номер телефона"
								variant="outlined"
								fullWidth
							/>
						</InputMask>
					</DialogContent>
					<DialogActions className={styles.justifyCenter}>
						{clientInfo.phone.search(/\+\d\s\d\d\d\s\d\d\d-\d\d-\d\d/g) >= 0 && checkPhone(clientInfo.phone) && (
							<Link
								to={"/reserve-request"}
								style={{
									width: "100%",
									color: "white",
									textDecoration: "none",
								}}>
								<BtnDarkBlue
									fullWidth
									variant="contained"
									size="large"
									type={"submit"}>
									Продолжить
								</BtnDarkBlue>
							</Link>
						)}
					</DialogActions>
				</form>
			)}
		</Dialog>
	);
};

export default ModalReserve;