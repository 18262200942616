import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import * as React from "react";
import { useDispatch } from "react-redux";
import { actions } from "@/redux/modules/Header";
import pm from "@/core/helpers/ProcessManager";
import { api } from "@/api/Api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

const theme = createTheme();

const basePath = process.env.PUBLIC_URL;

export const Login = () => {
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [keepLoggedIn, setKeepLoggedIn] = React.useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    pm.closeBackDrop();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (login && password) {
      pm.startLoading();
      api.camunda
        .login(login, password, keepLoggedIn)
        .then((userContext) => {
          pm.stopLoading();
          dispatch(actions.success(userContext));
          localStorage.setItem("userContext", JSON.stringify(userContext));

          if (userContext?.user?.roles?.find((item) => item.code === "ADMIN")) {
            navigate("/treasurer-rates");
          } else if (
            userContext?.user?.roles?.find((item) => item.code === "Treasurer")
          ) {
            navigate("/treasurer-rates");
          } else if (
            userContext?.user?.roles?.find((item) => item.code.indexOf("Cashier") >= 0)
          ) {
            navigate("/treasurer-rates");
          } else {
            navigate("/treasurer-rates");
          }
        })
        .catch((error) => {
          pm.stopLoading();
          dispatch(actions.failure(error.toString()));
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "70px",
              overflow: "hidden",
            }}>
            <IconButton href="/">
              <img
                src={`${basePath}/LOGOSVG1.svg`}
                style={{
                  width: "300px",
                }}
              />
            </IconButton>
          </div>
          <Typography component="h1" variant="h5">
            Войти
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Логин"
              name="login"
              autoComplete="login"
              autoFocus
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Запомнить меня"
              value={keepLoggedIn}
              onChange={() => setKeepLoggedIn(!keepLoggedIn)}
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, background: '#da793f', '&:hover': { background: '#b76534' } }}
            >
              Войти
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Забыл пароль?
                </Link> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
