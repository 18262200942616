import React from "react";
import { Htag } from '@/components';
import { ModalReserve, CurrencyTable, CurrencyCalculator } from '../../components';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styles from "./homePage.module.css";
import { useHomePage } from './useHomePage';

const basePath = process.env.PUBLIC_URL;

const HomePage = (): JSX.Element => {
	const {
		openWindow,
		setOpenWindow,
		handleChangePhone,
		clientInfo,
		departmentList,
		departmentStatus,
		activeRegion,
		regions,
		activeDepartment,
		rateExchange,
		handleSelectCurrency,
		handleSwapCurrency,
		handleChangeBuyAmount,
		handleChangeSaleAmount,
		nationalBankRates,
		handleSelectRegion,
		handleSelectActiveDepartment,
	} = useHomePage();

	return (
		<div className={styles.background_color}>
			{openWindow.open &&
				<ModalReserve
					openWindow={openWindow}
					setOpenWindow={setOpenWindow}
					handleChangePhone={handleChangePhone}
					clientInfo={clientInfo}
				/>
			}
			<div className={styles.container}>
				<div className={styles.logoContainer}>
					<div className={styles.logo}>
						<img src={`${basePath}/LOGOSVG1.svg`} />
					</div>
					<FormControl
						sx={{
							'& fieldset': {
								border: 0,
							},
							'& .MuiOutlinedInput-notchedOutline': {
								borderWidth: "0px !important"
							}
						}}
					>
						<Select
							sx={{
								color: '#e25500',
								fontWeight: 'bold',
								fontSize: '25px',
								'& svg': {
									color: '#e25500',
								}
							}}
							value={activeRegion}
							onChange={(e: any) => handleSelectRegion(e.target.value)}
						>
							{regions.map(region => (
								<MenuItem key={region} value={region}>{region}</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<Htag tag={'h1'}>Курсы валют</Htag>
				{/* <Htag tag={'h2'}>Забронируйте курс и закажите сопровождение нажав на интересующую валюту</Htag> */}
				{/* {departmentStatus === "resolve" && (
					<>
						<SelectDepartments regions={regions} isSelectKassa={false} />
					</>
				)} */}
				<div className={styles.currencyContainer}>
					{activeDepartment &&
						<>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<FormControl
									sx={{
										'& fieldset': {
											border: 0,
										},
										'& .MuiOutlinedInput-notchedOutline': {
											borderWidth: "0px !important"
										}
									}}
								>
									<Select
										sx={{
											color: 'black',
											fontWeight: 'bold',
											fontSize: '25px',
											'& svg': {
												color: 'black',
											}
										}}
										value={activeDepartment}
										onChange={(e: any) => handleSelectActiveDepartment(e.target.value.id)}
									>
										{departmentList.filter(department => department.code === activeRegion).map((department: any) => (
											<MenuItem key={department.id} value={department}>{department.address}</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<CurrencyTable
								currencyList={activeDepartment?.currencies.filter(currency => currency.code !== "KZT")}
								rateExchange={rateExchange}
								handleSelectCurrency={handleSelectCurrency}
							/>
							{/* <CurrencyCalculator
								rateExchange={rateExchange}
								handleChangeBuyAmount={handleChangeBuyAmount}
								handleChangeSaleAmount={handleChangeSaleAmount}
								handleSwapCurrency={handleSwapCurrency}
								setOpenWindow={setOpenWindow}
								nationalBankRates={nationalBankRates}
							/> */}
						</>
					}
				</div>
			</div>
		</div>
	);
};

export default HomePage;