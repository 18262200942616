import React from "react";
import { Routes, Route } from "react-router-dom";
import { HomePage, Reservation } from "./pages";

const ClientExchange = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    {/* Camunda начинается тут */}
    <Route
      path="/reserve-request"
      element={<Reservation />}
    />
  </Routes>
);

export default ClientExchange;
