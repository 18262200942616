import { api } from '@/api/Api';
import { CurrencyModel, KassaModel, NationalBankRate } from '@/interface';
import { ClientInfo, actions as clientInfoActions } from "@/redux/modules/ClientInfo";
import { RateExchange, actions as rateActions } from "@/redux/modules/RateExchange";
import { loadDepartments } from '@/redux/modules/department/departmentActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pm from "@/core/helpers/ProcessManager";

interface UseCashierExchangePageProps {
	activeKassa: KassaModel;
	loadAddCash: (params: { balance: number, currencyCode: string, kassaId: string | number }) => void;
}

export const useCashierExchangePage = ({ activeKassa, loadAddCash }: UseCashierExchangePageProps) => {
	const [openWindow, setOpenWindow] = useState(false);
	const [activeCurrency, setActiveCurrency] = useState<CurrencyModel>();
	const [minLimitToIndivRate, setMinLimitIndivRate] = useState(10000);
	const [isIndivRate, setIsIndivRate] = useState(false);
	const [newIndivRate, setNewIndivRate] = useState(0);
	const [showAllId, setShowAllId] = useState<string | number | null>(null);

	const dispatch = useDispatch();

	const clientInfo: ClientInfo = useSelector(
		(state: any) => state?.clientInfo || {}
	);

	const rateExchange: RateExchange = useSelector(
		(state: any) => state?.rateExchange || {}
	);

	const kztCurrency = activeKassa?.cash.find(c => c.currencyCode === "KZT");

	useEffect(() => {
		dispatch(clientInfoActions.updateClientInfo(
			(c) => c.iin,
			() => ""
		));
		handleGetDepartmentList();
	}, []);

	const handleGetDepartmentList = () => {
		dispatch(loadDepartments());
	};

	const handleOpenWindow = (
		currency: CurrencyModel,
		buyCurrencyCode: string,
		saleCurrencyCode: string,
		rate: number,
		kassaId: string | number,
		depsId: string | number
	) => {
		!(activeKassa?.cash.find(cur => cur.currencyCode === buyCurrencyCode)) && loadAddCash({ balance: 0, currencyCode: buyCurrencyCode, kassaId: activeKassa.id });
		!(activeKassa?.cash.find(cur => cur.currencyCode === saleCurrencyCode)) && loadAddCash({ balance: 0, currencyCode: saleCurrencyCode, kassaId: activeKassa.id });

		if (buyCurrencyCode !== "USD" && saleCurrencyCode !== "USD") {
			setLimit(buyCurrencyCode !== "KZT" ? buyCurrencyCode : saleCurrencyCode);
		} else {
			setMinLimitIndivRate(10000);
		}

		setOpenWindow(true);
		setActiveCurrency(currency);
		dispatch(
			rateActions.updateRateModel(
				buyCurrencyCode,
				saleCurrencyCode,
				"",
				"",
				rate,
				kassaId,
				null,
				false,
				depsId
			)
		);
	};

	const setShowAll = (depsId: number | null) => {
		setShowAllId(depsId);
	};

	const handleCloseWindow = () => {
		setOpenWindow(false);
		dispatch(clientInfoActions.updateClientInfo(
			(c) => c.iin,
			() => ""
		));
		setIsIndivRate(false);
		setNewIndivRate(0);
	};

	const handleChangeBuyAmount = (value: number, indivRate: number) => {
		let saleValue = 0;
		let rate = 0;

		if (rateExchange?.buyCurrencyCode === "KZT") {
			saleValue = Math.floor(value / rateExchange.rate);
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? saleValue < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].sale
						: activeCurrency.rates[1].sale
					: activeCurrency?.rates[1]?.limitTo &&
						saleValue < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].sale
						: activeCurrency?.rates[0].sale || 0
			).toFixed(2);

			if (saleValue >= minLimitToIndivRate) {
				saleValue = isIndivRate ? Math.floor(value / indivRate) : Math.floor(value / rate);
			} else {
				saleValue = Math.floor(value / rate);
				setNewIndivRate(0);
				setIsIndivRate(false);
			}
		} else {
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? value < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].buy
						: activeCurrency.rates[1].buy
					: activeCurrency?.rates[1]?.limitTo &&
						value < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].buy
						: activeCurrency?.rates[0].buy || 0
			).toFixed(2);

			if (value >= minLimitToIndivRate) {
				saleValue = isIndivRate ? Math.floor(value * indivRate) : Math.floor(value * rate);
			} else {
				saleValue = Math.floor(value * rate);
				setNewIndivRate(0);
				setIsIndivRate(false);
			}
		}

		dispatch(
			rateActions.updateRateModel(
				rateExchange.buyCurrencyCode,
				rateExchange.saleCurrencyCode,
				value === 0 ? "" : value,
				+saleValue === 0 ? "" : saleValue,
				rate,
				rateExchange.kassaId,
				null,
				rateExchange.isIndividual,
				rateExchange?.depsId || ""
			)
		);
	};

	const handleChangeSaleAmount = (value: number, indivRate: number) => {
		let buyValue = 0;
		let rate = 0;

		if (rateExchange?.buyCurrencyCode === "KZT") {
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? value < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].sale
						: activeCurrency.rates[1].sale
					: activeCurrency?.rates[1]?.limitTo &&
						value < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].sale
						: activeCurrency?.rates[0].sale || 0
			).toFixed(2);

			if (value >= minLimitToIndivRate) {
				buyValue = indivRate ? Math.ceil(value * indivRate) : Math.ceil(value * rate);
			} else {
				buyValue = Math.ceil(value * rate);
				setNewIndivRate(0);
				setIsIndivRate(false);
			}
		} else {
			buyValue = value / rateExchange.rate;
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? buyValue < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].buy
						: activeCurrency.rates[1].buy
					: activeCurrency?.rates[1]?.limitTo &&
						buyValue < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].buy
						: activeCurrency?.rates[0].buy || 0
			).toFixed(2);

			if (buyValue >= minLimitToIndivRate) {
				buyValue = isIndivRate ? Math.floor(value / indivRate) : Math.floor(value / rate);
			} else {
				buyValue = Math.floor(value / rate);
				setNewIndivRate(0);
				setIsIndivRate(false);
			}
		}

		dispatch(
			rateActions.updateRateModel(
				rateExchange.buyCurrencyCode,
				rateExchange.saleCurrencyCode,
				+buyValue === 0 ? "" : buyValue,
				value === 0 ? "" : value,
				rate,
				rateExchange.kassaId,
				null,
				rateExchange.isIndividual,
				rateExchange?.depsId || ""
			)
		);
	};

	const setLimit = async (currencyCode: string) => {
		let nationalBankRates: NationalBankRate[] = [];
		await api.parsing.loadNationalRates().then((res) => {
			nationalBankRates = res.rates.item;
		});

		const currentNationalBankCurrency = nationalBankRates?.find(
			(item) => item.title === currencyCode.toUpperCase()
		);
		const usdNationalBankCurrency = nationalBankRates?.find(
			(item) => item.title === "USD"
		);
		let valueOfStep = 0;
		if (usdNationalBankCurrency) {
			if (currentNationalBankCurrency) {
				valueOfStep = (10000 * +usdNationalBankCurrency?.description) / +currentNationalBankCurrency?.description;
			}
		} else {
			valueOfStep = 0;
		}

		if (valueOfStep > 10 && valueOfStep < 100) {
			valueOfStep = valueOfStep - (valueOfStep % 10);
		} else if (valueOfStep > 100 && valueOfStep < 1000) {
			valueOfStep = valueOfStep - (valueOfStep % 100);
		} else if (valueOfStep > 1000 && valueOfStep < 10000) {
			valueOfStep = valueOfStep - (valueOfStep % 1000);
		} else if (valueOfStep > 10000 && valueOfStep < 100000) {
			valueOfStep = valueOfStep - (valueOfStep % 10000);
		} else if (valueOfStep > 100000) {
			valueOfStep = valueOfStep - (valueOfStep % 100000);
		}

		setMinLimitIndivRate(valueOfStep);
	};

	const handleSubmitCurrencies = (isIndivRate: boolean, newIndivRate: number) => {
		const kztBalance: number = kztCurrency?.balance && kztCurrency?.balance ? kztCurrency?.balance : 0;

		const currencyBalance = activeKassa?.cash?.find(item => item.currencyCode === activeCurrency?.code)?.balance || 0;

		if (
			(rateExchange.buyCurrencyCode !== "KZT"
				&& +rateExchange.saleAmount <= kztBalance)
			||
			(rateExchange.buyCurrencyCode === "KZT"
				&& currencyBalance
				&& +rateExchange.saleAmount <= currencyBalance)
		) {
			dispatch(
				rateActions.updateRateModel(
					rateExchange.buyCurrencyCode,
					rateExchange.saleCurrencyCode,
					rateExchange.buyAmount,
					rateExchange.saleAmount,
					!isIndivRate ? rateExchange.rate : newIndivRate,
					rateExchange.kassaId,
					null,
					isIndivRate,
					rateExchange?.depsId || ""
				)
			);

			return true;
		} else {
			pm.openSnackBar("Недостаточно денег на кассе", { severity: "error" });
			return false;
		}
	};

	const handleSetIsIndivRate = () => {
		setIsIndivRate(!isIndivRate);
		if (isIndivRate) {
			rateExchange.buyCurrencyCode !== "KZT" ? handleChangeBuyAmount(+rateExchange.buyAmount, rateExchange.rate) : handleChangeSaleAmount(+rateExchange.saleAmount, rateExchange.rate);
			setNewIndivRate(0);
		} else {
			rateExchange.buyCurrencyCode !== "KZT" ? handleChangeBuyAmount(+rateExchange.buyAmount, newIndivRate) : handleChangeSaleAmount(+rateExchange.saleAmount, newIndivRate);
			setNewIndivRate(rateExchange.rate);
		}
	};

	const handleSetNewIndivRate = (num: number, maxNewIndivRate: number) => {
		const newRate = num;
		setNewIndivRate(newRate);
		console.log(maxNewIndivRate)
		rateExchange?.buyCurrencyCode !== "KZT" ? handleChangeBuyAmount(+rateExchange.buyAmount, newRate) : handleChangeSaleAmount(+rateExchange.saleAmount, newRate);
		if (rateExchange?.buyCurrencyCode !== "KZT" && newRate > maxNewIndivRate) {
			pm.openSnackBar("Индив курс слишком высокий", { severity: "error" });
		} else if (rateExchange?.buyCurrencyCode === "KZT" && newRate < maxNewIndivRate) {
			pm.openSnackBar("Индив курс слишком низкий", { severity: "error" });
		} else if (rateExchange?.buyCurrencyCode !== "KZT" && newRate <= rateExchange.rate) {
			pm.openSnackBar("Индив курс должен быть выше актуального курса", { severity: "error" });
		} else if (rateExchange?.buyCurrencyCode === "KZT" && newRate >= rateExchange.rate) {
			pm.openSnackBar("Индив курс должен быть ниже актуального курса", { severity: "error" });
		} else {
			pm.closeSnackBar();
		}
	};

	const setIsLoading = (open: boolean) => {
		open ? pm.openBackDrop() : pm.closeBackDrop();
	};

	return {
		handleOpenWindow,
		showAllId,
		setShowAll,
		kztCurrency,
		openWindow,
		handleCloseWindow,
		rateExchange,
		handleChangeBuyAmount,
		handleChangeSaleAmount,
		clientInfo,
		setIsLoading,
		handleSubmitCurrencies,
		minLimitToIndivRate,
		isIndivRate,
		newIndivRate,
		handleSetIsIndivRate,
		handleSetNewIndivRate,
	};
};