import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { RateExchange, actions as rateActions } from '@/redux/modules/RateExchange';

import { ClientInfo, actions as clientInfoActions } from "@/redux/modules/ClientInfo";

import { CurrencyModel, NationalBankRate, Regions } from '@/interface';
import { selectActiveCurrency, selectActiveDepartment, selectActiveRegion, selectDepartmentList, selectDepartmentStatus } from '@/redux/modules/department/departmentSelectors';
import { loadDepartments, setActiveCurrencyId, setActiveDepartmentId, setActiveKassaId, setActiveRegion } from '@/redux/modules/department/departmentActions';
import { api } from '@/api/Api';

export const useHomePage = () => {
	const dispatch = useDispatch();

	const [openWindow, setOpenWindow] = useState({
		open: false,
		text: "",
		button: false
	});
	const [nationalBankRates, setNationalBankRates] = useState<NationalBankRate[]>([]);

	const regions: ["Алматы", "Астана"] = ["Алматы", "Астана"];

	const activeDepartment = useSelector(selectActiveDepartment);

	const departmentList = useSelector(selectDepartmentList);
	const departmentStatus = useSelector(selectDepartmentStatus);
	const activeCurrency = useSelector(selectActiveCurrency);

	const activeRegion = useSelector(selectActiveRegion);

	const rateExchange: RateExchange = useSelector(
		(state: any) => state?.rateExchange || {}
	);

	const clientInfo: ClientInfo = useSelector(
		(state: any) => state?.clientInfo || {}
	);

	useEffect(() => {
		localStorage.removeItem("state");

		callLoadDeps();

		callLoadNationalBankRates();

		handleChangePhone("");
	}, []);

	useEffect(() => {
		if (!activeDepartment) {
			const currenctDepartment = departmentList.find(department => department.code === activeRegion);
			currenctDepartment && dispatch(setActiveDepartmentId(currenctDepartment.id));
		}
	}, [departmentList]);

	const callLoadDeps = () => {
		dispatch(loadDepartments());
	};

	const callLoadNationalBankRates = async () => {
		await api.parsing.loadNationalRates()
			.then((res) => setNationalBankRates(res.rates.item));
	};

	const handleSelectCurrency = (currency: CurrencyModel) => {
		dispatch(setActiveCurrencyId(activeDepartment?.id || 9999, currency.currencyId + ''));

		let buyCode = "";
		let saleCode = "";
		let rate = 0;
		let buyAmount: string | number = 0;
		let saleAmount: string | number = 0;

		if (rateExchange.buyCurrencyCode !== "KZT") {
			buyCode = currency.code;
			saleCode = "KZT";
			buyAmount = +rateExchange.buyAmount === 0 ? "" : rateExchange.buyAmount;
			rate = +(
				currency?.rates[0]?.limitFrom === 0
					? +rateExchange.buyAmount < currency.rates[0]?.limitTo
						? currency.rates[0].buy
						: currency.rates[1].buy
					: currency?.rates[1]?.limitTo &&
						+rateExchange.buyAmount < currency?.rates[1]?.limitTo
						? currency.rates[1].buy
						: currency.rates[0].buy).toFixed(2);
			saleAmount = +buyAmount === 0 ? "" : Math.floor(+buyAmount * rate);
		} else {
			saleCode = currency.code;
			buyCode = "KZT";
			saleAmount =
				+rateExchange.saleAmount === 0 ? "" : rateExchange.saleAmount;
			rate = +(
				currency?.rates[0]?.limitFrom === 0
					? +rateExchange.saleAmount < currency.rates[0]?.limitTo
						? currency.rates[0].sale
						: currency.rates[1].sale
					: currency?.rates[1].limitTo &&
						+rateExchange.saleAmount < currency?.rates[1]?.limitTo
						? currency.rates[1].sale
						: currency.rates[0].sale).toFixed(2);
			buyAmount = +saleAmount === 0 ? "" : Math.ceil(+saleAmount * rate);
		}
		dispatch(
			rateActions.updateRateModel(
				buyCode,
				saleCode,
				buyAmount,
				saleAmount,
				rate,
				9999,
				null,
				false,
				activeDepartment?.id || 9999,
			)
		);
	};

	const handleChangeBuyAmount = (value: number) => {
		let saleValue = 0;
		let rate = 0;

		if (rateExchange?.buyCurrencyCode === "KZT" && activeCurrency) {
			saleValue = Math.floor(value / rateExchange.rate);
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? saleValue < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].sale
						: activeCurrency.rates[1].sale
					: activeCurrency?.rates[1]?.limitTo &&
						saleValue < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].sale
						: activeCurrency.rates[0].sale
			).toFixed(2);

			saleValue = Math.floor(value / rate);
		} else if (rateExchange?.buyCurrencyCode !== "KZT" && activeCurrency) {
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? value < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].buy
						: activeCurrency.rates[1].buy
					: activeCurrency?.rates[1]?.limitTo &&
						value < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].buy
						: activeCurrency.rates[0].buy
			).toFixed(2);

			saleValue = Math.floor(value * rate);
		}

		dispatch(
			rateActions.updateRateModel(
				rateExchange.buyCurrencyCode,
				rateExchange.saleCurrencyCode,
				value === 0 ? "" : value,
				+saleValue === 0 ? "" : Math.floor(+saleValue),
				rate,
				rateExchange.depsId,
				null,
				false,
				rateExchange.depsId,
			)
		);
	};

	const handleChangeSaleAmount = (value: number) => {
		let buyValue = 0;
		let rate = 0;

		if (rateExchange?.buyCurrencyCode === "KZT" && activeCurrency) {
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? value < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].sale
						: activeCurrency.rates[1].sale
					: activeCurrency?.rates[1]?.limitTo &&
						value < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].sale
						: activeCurrency.rates[0].sale
			).toFixed(2);

			buyValue = Math.ceil(value * rate);
		} else if (rateExchange?.buyCurrencyCode !== "KZT" && activeCurrency) {
			buyValue = Math.ceil(value / rateExchange.rate);
			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? buyValue < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].buy
						: activeCurrency.rates[1].buy
					: activeCurrency?.rates[1]?.limitTo &&
						buyValue < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].buy
						: activeCurrency.rates[0].buy
			).toFixed(2);

			buyValue = Math.ceil(value / rate);
		}

		dispatch(
			rateActions.updateRateModel(
				rateExchange.buyCurrencyCode,
				rateExchange.saleCurrencyCode,
				+buyValue === 0 ? "" : Math.ceil(+buyValue),
				value === 0 ? "" : value,
				rate,
				rateExchange.depsId,
				null,
				false,
				rateExchange.depsId,
			)
		);
	};

	const handleSwapCurrency = () => {
		const buyCurrencyCode = rateExchange.saleCurrencyCode;
		const saleCurrencyCode = rateExchange.buyCurrencyCode;
		let rate = 0;
		let buyAmount: string | number = 0;
		let saleAmount: string | number = 0;

		if (buyCurrencyCode !== "KZT" && activeCurrency) {
			buyAmount =
				+rateExchange.saleAmount === 0 ? "" : +rateExchange.saleAmount;

			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? +buyAmount < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].buy
						: activeCurrency.rates[1].buy
					: activeCurrency?.rates[1]?.limitTo &&
						+buyAmount < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].buy
						: activeCurrency.rates[0].buy
			).toFixed(2);

			saleAmount = +buyAmount === 0 ? "" : Math.floor(+buyAmount * rate);
		} else if (buyCurrencyCode === "KZT" && activeCurrency) {
			saleAmount = +rateExchange.buyAmount === 0 ? "" : +rateExchange.buyAmount;

			rate = +(
				activeCurrency?.rates[0]?.limitFrom === 0
					? +saleAmount < activeCurrency.rates[0]?.limitTo
						? activeCurrency.rates[0].sale
						: activeCurrency.rates[1].sale
					: activeCurrency?.rates[1]?.limitTo &&
						+saleAmount < activeCurrency?.rates[1]?.limitTo
						? activeCurrency.rates[1].sale
						: activeCurrency.rates[0].sale
			).toFixed(2);

			buyAmount = +saleAmount === 0 ? "" : Math.ceil(+saleAmount * rate);
		}
		dispatch(
			rateActions.updateRateModel(
				buyCurrencyCode,
				saleCurrencyCode,
				buyAmount,
				saleAmount,
				rate,
				rateExchange.depsId,
				null,
				false,
				rateExchange.depsId,
			)
		);
	};

	const handleChangePhone = (phone: string) => {
		dispatch(clientInfoActions.updatePhone(phone));
	};

	const handleSelectRegion = (region: Regions | "Все") => {
		if (region !== activeRegion) {
			dispatch(setActiveRegion(region));
			const foundDepartment = departmentList.find(department => department.code === region);
			foundDepartment?.id && dispatch(setActiveDepartmentId(foundDepartment?.id));
			foundDepartment?.kassa[0].id && dispatch(setActiveKassaId(foundDepartment?.kassa[0].id));
			// foundDepartment?.kassa[0].id && handleCheckKassaIsOpen(foundDepartment?.kassa[0].id);
		} else {
			// activeKassa?.id && handleCheckKassaIsOpen(activeKassa.id);
		}
	};

	const handleSelectActiveDepartment = (id: string | number) => {
		if (id !== activeDepartment?.id) {
			dispatch(setActiveDepartmentId(id));
			const foundKassa = departmentList?.find(department => department.id === id)?.kassa[0];
			foundKassa?.id && dispatch(setActiveKassaId(foundKassa.id));
			// foundKassa && handleCheckKassaIsOpen(foundKassa?.id);
		} else {
			// activeKassa?.id && handleCheckKassaIsOpen(activeKassa.id);
		}
	};

	return {
		openWindow,
		setOpenWindow,
		handleChangePhone,
		clientInfo,
		departmentList,
		departmentStatus,
		activeRegion,
		regions,
		activeDepartment,
		rateExchange,
		handleSelectCurrency,
		handleChangeBuyAmount,
		handleChangeSaleAmount,
		handleSwapCurrency,
		nationalBankRates,
		handleSelectRegion,
		handleSelectActiveDepartment,
	};
};