import React, { useState } from "react";
import { CurrencyTableProps } from './CurrencyTable.props';
import { formatNumberRu } from '@/core/helpers/PageHelpers';
import { Switch } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./CurrencyTable.module.css";

const basePath = process.env.PUBLIC_URL;

const CurrencyTable = ({ currencyList, rateExchange, handleSelectCurrency, className, ...props }: CurrencyTableProps): JSX.Element => {
	const [showAll, setShowAll] = useState(true);
	const [isOptom, setIsOptom] = useState(true);

	const mappedCurrencyList = currencyList
		?.filter(currency => currency.code !== "KZT" && currency.code.indexOf("GOLD") < 0)
		.map((currency, index) => {
			if ((!showAll && index <= 2) || showAll) {
				const isSelectedCurrency = currency.code === rateExchange.buyCurrencyCode || currency.code === rateExchange.saleCurrencyCode;
				return (
					<div
						key={currency.currencyId}
						className={styles.currency_list_row + ' ' + styles.row}
						style={{
							// background: isSelectedCurrency ? "#e9e9e9" : "unset",
							// cursor: isSelectedCurrency ? "unset" : "pointer"
							cursor: "unset"
						}}
						onClick={() => {
							if (
								currency.code !== rateExchange.buyCurrencyCode &&
								currency.code !== rateExchange.saleCurrencyCode
							) {
								handleSelectCurrency(currency);
							}
						}}
					>
						<div className={styles.currency_flag_img}>
							<img
								src={`${basePath}/assets/images/Flags/${currency.code}.png`}
							/>
							<div>
								<p>{currency.code}</p>
								<p style={{ fontSize: '12px' }}>{currency.description}</p>
							</div>
						</div>
						<p style={{ textAlign: "center" }}>
							{!isOptom
								? (
									currency.rates[0].limitFrom === 0
										? currency.rates[0].buy.toFixed(2)
										: currency.rates[1].buy.toFixed(2)
								)
								: (
									currency.rates[0].limitFrom !== 0
										? currency.rates[0].buy.toFixed(2)
										: currency.rates[1].buy.toFixed(2)
								)
							}
						</p>
						<p style={{ textAlign: "center" }}>
							{!isOptom
								? (
									currency.rates[0].limitFrom === 0
										? currency.rates[0].sale.toFixed(2)
										: currency.rates[1].sale.toFixed(2)
								)
								: (
									currency.rates[0].limitFrom !== 0
										? currency.rates[0].sale.toFixed(2)
										: currency.rates[1].sale.toFixed(2)
								)
							}
						</p>
						{/* <p style={{ paddingLeft: "10px", fontWeight: 'bold', fontSize: '20px', textAlign: 'right' }}>
							{!isOptom
								? (
									currency.rates[0].limitFrom === 0
										? <><span style={{ fontSize: "14px" }}>менее </span> {formatNumberRu(currency.rates[0].limitTo)}</>
										: <><span style={{ fontSize: "14px" }}>менее </span> {formatNumberRu(currency.rates[1].limitTo)}</>
								)
								: (
									currency.rates[0].limitFrom !== 0
										? <><span style={{ fontSize: "14px" }}>более </span> {formatNumberRu(currency.rates[0].limitFrom)}</>
										: <><span style={{ fontSize: "14px" }}>более </span> {formatNumberRu(currency.rates[1].limitFrom)}</>
								)
							}
						</p> */}
					</div>
				);
			}
		});

	return (
		<div
			className={className + " " + styles.currency_list_container}
			{...props}
		>
			<div className={styles.currency_list_row + " " + styles.header_row}>
				<p style={{ textAlign: "center" }}>Валюта</p>
				<p style={{ textAlign: "center" }}>Покупка</p>
				<p style={{ textAlign: "center" }}>Продажа</p>
				{/* <p
					style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
				>
					{isOptom ? 'Оптом' : 'Розница'}
					<Switch
						checked={isOptom}
						onChange={() => setIsOptom(!isOptom)}
					/>
				</p> */}
			</div>
			{mappedCurrencyList}
			{/* {currencyList.filter(currency => currency.code !== "KZT" && currency.code !== "GOLD").length > 3 &&
				(
					showAll
						? <div
							className={styles.showAll}
							onClick={() => setShowAll(false)}
						>
							Скрыть <KeyboardArrowUpIcon />
						</div>
						: <div
							className={styles.showAll}
							onClick={() => setShowAll(true)}
						>
							Eще <KeyboardArrowDownIcon />
						</div>
				)
			} */}
		</div>
	);
};

export default CurrencyTable;